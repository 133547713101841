.all-product-container .all-product-heading {
    font-size: 24px;
    line-height: 29px;
    color: var(--font-color);
    letter-spacing: 2px;
    font-weight: 700 !important;
    margin: 0;
    margin-bottom: 12px;
}

.all-product-container .pagination {
    margin-top: 24px;
}

.all-product-container .load-mote-btn {
    background-color: #29363F;
    padding: 6px 12px;
    gap: 4px;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}