 .forget-password .input-container label {
     font-size: 16px;
     font-weight: 700;
     line-height: 19px;
     margin-bottom: 8px;
 }

 .forget-password .forget-password-box {
     border: 1px solid #000;
     padding: 8px 16px;
     width: 100%;
     border-radius: 4px;
     font-size: 16px;

 }

 .forget-password .forget-password-box::placeholder {
     text-align: left !important;
 }

 .forget-password h5 {
     font-weight: bolder;
     font-size: 3rem;
 }

 .forget-password button[type="submit"] {
     margin-top: 20px;
     font-size: 14px;
     font-weight: 400 !important;
     letter-spacing: 1px;
     padding: 11px;
     color: var(--second-cards-color);
     text-decoration: none;
     background-color: var(--secondary-color);
     border-radius: 5px;
     width: 100%;
 }

 .forget-password .input-container .password-input-wrapper {
     position: relative;
     display: flex;
     align-items: center;
 }

 .forget-password .forget-eye-icon {
     position: absolute;
     right: 10px;
     cursor: pointer;
     color: #666;
     top: 6px;
 }