.reset-password-container .reset-password-heading {
    background: var(--second-cards-color);
    font-size: 2.5rem;
    font-weight: bolder;
    padding: 15px;
    border-radius: 5px;
    color: var(--font-color);
    position: relative;
}

.reset-password-container .reset-password-input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    background: var(--second-cards-color);
    border-radius: 8px;
    gap: 15px;
    border-bottom: 1px solid rgba(155, 151, 151, 0.205);
    padding-bottom: 50px;
}


/* Default styling for larger screens */
.reset-password-container .reset-password-input-wrapper .password,
.reset-password-container .reset-password-input-wrapper .new-password,
.reset-password-container .reset-password-input-wrapper .confirm-new-password {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* Styling for screens with max-width 430px */
@media screen and (max-width: 430px) {

    .reset-password-container .reset-password-input-wrapper .password,
    .reset-password-container .reset-password-input-wrapper .new-password,
    .reset-password-container .reset-password-input-wrapper .confirm-new-password {
        width: 100%;
    }
}


.reset-password-container .reset-password-input-wrapper input {
    padding: 10px;
    background: rgba(155, 151, 151, 0.205);
    border-radius: 10px;
    background-color: var(--buttons-background-color);
    height: 56px;
    width: 100%;
    transition: all 0.3s;

}

.reset-password-container .forget-password-button {
    background: var(--secondary-color);
    border-radius: 5px;
    padding: 5px 10px;
    margin: 10px;
    color: #fff;
    font-size: 2rem;
    float: right !important;
}

.reset-password-eye-icon {
    position: absolute;
    top: 16px;
    right: 110px;
}